.adtitleLand {
  display: flex;
  margin: 0px auto 15px auto;
  width: 480px;

  .adtitleCol {
    flex: 1;
    font-size: 16px;
    color: #333333;
  }

  .btns {
    text-align: right;

    a {
      font-size: 14px;
      color: #ff3434;
      text-decoration: underline;
    }
  }
}

.adformLand {
  margin: 0px auto;
  width: 480px;
}

.intelReminder {
  width: 480px;
  padding: 5px;
  margin: 0px auto 10px auto;
  background-color: #fffbe6;
  border: 1px solid #ffe58f;
  font-size: 12px;
  color: #ff9719;
  text-align: center;
}

.from {
  :global {
    .ant-form-item {
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      color: rgba(0, 0, 0, 0.65);
      font-size: 14px;
      font-variant: tabular-nums;
      line-height: 1.5;
      list-style: none;
      -webkit-font-feature-settings: 'tnum';
      font-feature-settings: 'tnum', "tnum";
      margin-bottom: 2px;
      vertical-align: top;
    }
  }
}