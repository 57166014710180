.sendcode {
  float: right;
}

.from {
  :global {
    .ant-form-item {
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      color: rgba(0, 0, 0, 0.65);
      font-size: 14px;
      font-variant: tabular-nums;
      line-height: 1.5;
      list-style: none;
      -webkit-font-feature-settings: 'tnum';
      font-feature-settings: 'tnum', "tnum";
      margin-bottom: 12px;
      vertical-align: top;
    }
  }
}

.Switch {
  float: right;
  // display: flex;
 // align-items: center;
 // justify-content: center;

}