.colWrap {
  text-align: center;
}

.borderRight {
  border-right: 1px solid #D5D9DB;
}

.coltitle {
  margin-bottom: 10px;
}

.label::after {
  content: ':';
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}