.des {
  :global {
    .ant-descriptions-row th {
      text-align: center;
    }

    .ant-descriptions-row td {
      text-align: center
    }
  }
}

.info {
  display: flex;
}

.txt {
  width: 100px;

  &::after {
    content: "：";
  }

  &::before {
    content: "*";
    color: red;
  }
}

.btn {
  text-align: center;
}

.imgmain {
  position: relative;


  .showdivimg {
    width: 120px;
    height: 120px;
    border: 1px solid rgb(197, 195, 195);
    border-radius: 5px;
  }

  img {
    width: 100px;
    height: 100px;
    background-size: cover;
    margin: 10px;
    cursor: pointer;

  }
}

.mainmask {
  background-color: rgba(101, 101, 101, 0.6);
  color: #ffffff;
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
}

.imgmain:hover .mainmask {
  opacity: 1;
}

.mask {
  text-align: center;
  margin-top: 40%;
}

.thumbWrap {
  display: block;
  margin: 0px 8px 8px 0px;
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  width: 104px;
  height: 104px;
  overflow: hidden;

  .thumbInfo {
    position: relative;
    height: 100%;
    overflow: hidden;
    transition: background-color 0.3s;

    img {
      position: static;
      width: 100%;
      height: 100%;
    }
  }

  .prewModal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.5);
    transform: all 0.3s;
    color: #fff;
    opacity: 0;

    &:hover {
      opacity: 1;
    }
  }
}