.header {
  margin: 10px 66px 0px 35px;
  
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-size: 24px;
  font-weight: bold;
  color: #204F7A;
}

.content {
  margin: 100px auto 100px auto;
  width: 80%;
}

.backgroundBox {
  position: relative;
  margin: 0px auto;
  width: 557px;
  height: 219px;
  background-image: url('~@/assets/images/icon/upload_charts_background.png');
  background-repeat: no-repeat;
}

.totalLegendWrap {
  position: absolute;
  top: 38px;
  left: 340px;
  display: flex;
  align-items: center;

  &::before {
    content: ' ';
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: rgba(249, 112, 17, 1);
  }
}

.totalLegendLine {
  width: 172px;
  height: 1px;
  background-color: rgba(249, 112, 17, 1);
}

.totalPoint {
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: rgba(249, 112, 17, 1);
}

.successLegendWrap {
  position: absolute;
  top: 90px;
  left: 368px;
  display: flex;
  align-items: center;

  &::before {
    content: ' ';
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: rgba(62, 123, 250, 1);
  }
}

.successLegendLine {
  width: 212px;
  height: 1px;
  background-color: rgba(62, 123, 250, 1);
}

.successPoint {
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: rgba(62, 123, 250, 1);
}

.failLegendWrap {
  position: absolute;
  top: 160px;
  left: 365px;
  display: flex;
  align-items: center;

  &::before {
    content: ' ';
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: rgba(255, 75, 74, 1);
  }
}

.failLegendLine {
  width: 246px;
  height: 1px;
  background-color: rgba(255, 75, 74, 1);
}

.failPoint {
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: rgba(255, 75, 74, 1);
}

.legendContent,
.labelContent {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -5px;
}

.labelContent {
  margin-right: -5px;
}

.totalLegend {
  position: relative;
  width: 118px;
  height: 37px;
  background-image: url('~@/assets/images/icon/upload_total_labelbox.png');
  background-repeat: no-repeat;
  font-size: 18px;
  color: #333333;
  opacity: 0.5;
  font-weight: bold;
}

.successLegend {
  width: 159px;
  height: 41px;
  background-image: url('~@/assets/images/icon/upload_success_labelbox.png');
  background-repeat: no-repeat;
  font-size: 20px;
  color: #333333;
  opacity: 0.5;
  font-weight: bold;
}

.failLegend {
  width: 178px;
  height: 50px;
  background-image: url('~@/assets/images/icon/upload_fail_labelbox.png');
  background-repeat: no-repeat;
  font-size: 24px;
  color: #333333;
  opacity: 0.5;
  font-weight: bold;
}

.totalLabelWrap,
.successLabelWrap,
.failLabelWrap {
  display: flex;
  align-items: center;
}

.totalLabelWrap {
  position: absolute;
  top: 25px;
  right: 310px;

  &::after {
    content: ' ';
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: rgba(249, 112, 17, 1);
  }
}

.successLabelWrap {
  position: absolute;
  top: 160px;
  right: 420px;

  &::after {
    content: ' ';
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: rgba(62, 123, 250, 1);
  }
}

.failLabelWrap {
  position: absolute;
  top: 90px;
  right: 310px;

  &::after {
    content: ' ';
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: rgba(255, 75, 74, 1);
  }
}

.footer {
  display: flex;
  margin: 0px 48px;
}

.flexbox {
  flex: 1;
  display: flex;
  align-items: center;
  width: 483px;
  height: 118px;
  margin-left: 12px;

  &:first-child {
    margin-left: 0px;
  }
}

.iconBox {
  margin-left: 22px;
  display: block;
  width: 61px;
  height: 61px;
}


.flexboxBody {
  margin-left: 72px;
}

.bodyContent {
  font-size: 40px;
  font-weight: 400;
  line-height: normal;
}

.total {
  background-image: url('~@/assets/images/icon/upload_total_background.png');
  background-repeat: no-repeat;

  &.backgoundHover {
    background-image: url('~@/assets/images/icon/upload_total_background-after.png');
    background-repeat: no-repeat;
  }

  .bodyContent {
    color: #493F91;
  }
}


.success {
  background-image: url('~@/assets/images/icon/upload_success_background.png');
  background-repeat: no-repeat;

  &.backgoundHover {
    background-image: url('~@/assets/images/icon/upload_success_background-after.png');
    background-repeat: no-repeat;
  }

  .bodyContent {
    color: #18B3D0;
  }
}

.fail {
  background-image: url('~@/assets/images/icon/upload_fail_background.png');
  background-repeat: no-repeat;

  &.backgoundHover {
    background-image: url('~@/assets/images/icon/upload_fail_background-after.png');
    background-repeat: no-repeat;
  }

  .bodyContent {
    color: #ED8711;
  }
}

.unit {
  font-size: 15px;
}

.description {
  font-size: 15px;
  color: #333333;
  font-weight: 400;
}

.isHover {
  opacity: 1;
}