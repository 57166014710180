.wrap {
  display: flex;
}

.leftMenu {
  width: 224px;
  border-right: 1px solid #F0F0F0;

  :global {
    .ant-menu-inline {
      border: none;
    }
  } 
}

.rightContent {
  flex: 1;
  padding: 0px 36px;
}
