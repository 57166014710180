
.globalHeader {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0px 24px;
  height: 100%;
  background-color: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 21, 41, 0.08);
}

.logo {
  display: block;
  width: 180px;
}

.spaceContent {
  display: flex;
  align-items: center;
  margin-left: auto;
  overflow: hidden;
}

.spaceItem {
  padding: 0px 8px;
}