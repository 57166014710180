.valueFormitem {
  visibility: hidden;
}

.showFormitem {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;

  .showControl {
    display: inline-block;
    width: calc(100% - 120px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.confirmTips {
  text-align: center;
  font-size: 16px;
  color: #333333;
}

.footerLand {
  display: flex;

  .flex {
    flex: 1;
  }

  .footerTips {
    font-size: 14px;
    color: #333333;
  }
}

.formContent {
  display: flex;

  .formitemRow {
    flex: 1;

    :global {
      .ant-form-item {
        display: flex;
      }

      .ant-form-item-control-wrapper {
        flex: 1;
      }
    }
  }
}

.linkTips {
  position: relative;
  color: #999999;

  .leftText {
    display: block;
    width: 35px;
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
  }

  .rightText {
    display: block;
    width: 65px;
    position: absolute;
    right: 0px;
    top: 0px;
    bottom: 0px;
    text-align: right;
  }

  .linkContent {
    display: block;
    padding-left: 35px;
    padding-right: 65px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:hover {
      color: #ff4444;
    }
  }
}