.h2txt {
  font-weight: bold;
}

.labeltxt {
  margin-right: 10px;
}

.dectxt {
  color: #C1C2C5;
  font-size: 10px;
}

.handleOk {
  margin-left: 30%;
}

.thumbWrap {
  display: block;
  margin: 0px 8px 8px 0px;
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  width: 104px;
  height: 104px;
  overflow: hidden;

  .thumbInfo {
    position: relative;
    height: 100%;
    overflow: hidden;
    transition: background-color 0.3s;

    img {
      position: static;
      width: 100%;
      height: 100%;
    }
  }

  .prewModal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.5);
    transform: all 0.3s;
    color: #fff;
    opacity: 0;

    &:hover {
      opacity: 1;
    }
  }
}

.searchLand {
  position: relative;

  .uncompanyLand {
    padding-bottom: 15px;
    position: absolute;
    top: 75px;
    right: 0px;
    left: 1px;
    z-index: 90;
    max-height: 210px;
    overflow-y: auto;
    background-color: #ffffff;
    border-left: 1px solid #1890ff;
    border-right: 1px solid #1890ff;
    border-bottom: 1px solid #1890ff;
    border-radius: 0px 0px 4px 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

    ul {
      margin: 0px;
      padding: 0px;
    }

    li {
      padding: 5px 15px;
      cursor: pointer;
      line-height: normal;
      list-style: none;


      &:hover {
        background-color: #e6f7ff;
      }
    }
  }
}