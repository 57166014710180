.divred {
  color: red;
}

.divgreen {
  color: green;
}

.resultbtn {
  float: left;
}

.flexbox {
  flex: 1;
  display: flex;
  align-items: center;
  width: 200px;
  height: 100px;
  margin-left: 32px;

  &:first-child {
    margin-left: 0px;
  }
}

.iconBox {
  margin-left: 2px;
  margin-top: 20px;
  display: block;
  width: 96px;
  height: 108px;
}


.flexboxBody {
  margin-left: 52px;
}

.total {
  background-image: url('~@/assets/images/icon/beforeUpload_total_background.png');
  background-repeat: no-repeat;

  &.backgoundHover {
    background-image: url('~@/assets/images/icon/beforeUpload_total_background-after.png');
    background-repeat: no-repeat;
  }

  .bodyContent {
    color: #493F91;
  }
}


.success {
  background-image: url('~@/assets/images/icon/beforeUpload_success_background.png');
  background-repeat: no-repeat;

  &.backgoundHover {
    background-image: url('~@/assets/images/icon/beforeUpload_success_background-after.png');
    background-repeat: no-repeat;
  }

  .bodyContent {
    color: #18B3D0;
  }
}

.fail {
  background-image: url('~@/assets/images/icon/beforeUpload_fail_background.png');
  background-repeat: no-repeat;

  &.backgoundHover {
    background-image: url('~@/assets/images/icon/beforeUpload_fail_background-after.png');
    background-repeat: no-repeat;
  }

  .bodyContent {
    color: #ED8711;
  }
}

.opeatesbtn {
  float: right;
}

.titlenum {
  display: flex;
  justify-content: space-between;
  align-items: left;
  width: 100%;

  .titleson {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: left;
  }
}

.sonborder {
  border-left: 1px solid rgb(207, 211, 209);
  border-right: 1px solid rgb(207, 211, 209);
  padding: 0 300px;
}