.show {
  display: flex;
  float: right;
  align-items: center;
  text-align: center;
  margin-bottom: 5px;

  div {
    margin-right: 10px;
  }
}

.wh {
  width: 30px;
  height: 30px;
}

.bgIn {
  background-color: #ff9c00;
}

.bgOut {
  background-color: #ff5d2a;
}

.LEVEL_2 {
  color: #ff9c00;
}

.LEVEL_1 {
  color: #ff5d2a;
}