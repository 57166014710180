.accountSettings {
  display: flex;
  width: 100%;
  height: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
  background-color: #fff;
}

.accountLeftMenu {
  width: 224px;
  border-right: 1px solid #f0f0f0;

  :global {
    .ant-menu-inline {
      border: none;
    }
  }
}

.accountMain {
  flex: 1 1;
  padding: 8px 40px;
}