
.main {
  position: relative;
  background-image: url('~@/assets/images/login_background.png');
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
  width: 100%;
  -moz-background-size: 100% 100%;
  background-size: 100% 100%;
}

.formWrap {
  position: fixed;
  padding-top: 100px;
  padding-left: 38px;
  padding-right: 38px;
  top: 45%;
  width: 450px;
  height: 450px;
  border-radius: 3px;
  right: 10%;
  transform: translate3d(0, -50%, 0);
  background-color: #FFFFFF;
}

.title {
  color: #155BD4;
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 20px;
  text-align: center;
}

.btn {
  width: 100%;
  height: 44px;
}

.logoWrap {
  position: absolute;
  left: 80px;
  bottom: 5%;
  display: flex;
  align-items: center;

  .pic {
    display: block;
    margin-right: 20px;
    width: 84px;
    height: 84px;
  }

  .subtitle {
    font-size: 42px;
    color: #FFFFFF;
    line-height: normal;
  }

  .descript {
    font-size: 32px;
    color: #FFFFFF;
    line-height: normal;
  }
}

.footer {
  padding: 12px 0px;
  background-color: #181A1A;
  text-align: center;
  color: #999999;
  
  a {
    color: #999999;

    &:hover {
      color: #999999;
    }
  }
}