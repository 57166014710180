.homeWrapper {
  width: 100%;
  height: 100%;
  background-color: #ffffff;

  .containerWrap {
    padding-top: 80px;
    margin-bottom: 20px;
  }

  .pic {
    display: block;
    margin: 0px auto;
  }

  h3 {
    font-size: 26px;
    color: rgba(51, 51, 51, 0.8);
    text-align: center;
  }

  p {
    text-align: center;
    font-size: 16px;
    color: #666666;
  }
}