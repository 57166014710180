.basicLayout {
  background-color: #FBFAFA;
}

.layoutContent {
  margin: 24px;
  background-color: #FFFFFF;
  box-shadow: 0px 16px 28px 1px rgba(141,143,182,0.102);
}

.header {
  height: 72px;
  line-height: 72px;
  background-color: transparent;
}

.fixedHeader {
  position: fixed;
  top: 0px;
  right: 0px;
  padding: 0px;
  width: 100%;
  height: 72px;
  z-index: 100;
  background-color: #FFFFFF;
}

.basicLayoutFooter {
  margin: 16px 0px;
  padding: 0px 16px;
  text-align: center;
  color: #333333;
  background-color: #FBFAFA;
}