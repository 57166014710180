html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  list-style: none;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.ant-table-thead>tr>th,
.ant-table-tbody>tr>td {
  padding: 8px 10px;
  font-size: 14px;
}

.ant-table-thead>tr>th {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
}

.clearfix {
  zoom: 1;
}

.clearfix::after {
  content: '';
  height: 0;
  line-height: 0;
  display: block;
  visibility: hidden;
  clear: both;
}

.redColor {
  color: #FF4B4A;
}

.darkBackground {
  background-color: #D4DCE4;
}

.label {
  position: relative;
  color: rgba(0, 0, 0, 0.85);
}

.label::after {
  content: ':';
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}