.leftSider {
  padding-top: 72px;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 100;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
  background-color: transparent;
}

.asideWrap {
  display: flex;
  flex-direction: column;
  padding-top: 54px;
  height: 100%;
}

.asideLogo {
  position: relative;
  display: flex;
  align-items: center;
  padding: 16px;
  transition: padding 0.3s;
}

.asideContent {
  flex: 1 1 0%;
  overflow: hidden auto;

  :global {
    .ant-menu {
      background-color: transparent;
    }

    .ant-menu-inline {
      border-right: none;
    }

    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      color: #FFFFFF;
      background-color: #155BD4;
    }
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: hsla(0, 0%, 100%, 0.2);
    border-radius: 3px;
  }

  &::-webkit-scrollbar-track {
    background: hsla(0, 0%, 100%, .15);
    border-radius: 3px;
  }
}

.iconPic {
  display: inline-block;
  margin-right: 10px;
  width: 20px;
  height: 20px;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -4px;
}