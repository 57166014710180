.form {
  width: 400px;

  :global {
    .ant-form-item {
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      color: rgba(0, 0, 0, 0.65);
      font-size: 14px;
      font-variant: tabular-nums;
      line-height: 1.5;
      list-style: none;
      -webkit-font-feature-settings: 'tnum';
      font-feature-settings: 'tnum', "tnum";
      // margin-bottom: 24px;
      vertical-align: top;
    }
  }
}

.sendcode {
  float: right;
}

.btn {
  text-align: left;
  margin: 20px 0px;
}

.formExtra {
  position: relative;

  &:before {
    content: '*';
    display: inline-block;
    margin-right: 4px;
    color: #f5222d;
    font-size: 14px;
    font-family: 'SimSun', 'sans-serif';
    line-height: 1;
  }
}

.maindiv {
  border-bottom: 1px solid #E7EBEF;
  padding-bottom: 10px;
  margin-top: 10px;

  .txt {
    margin-top: 6px;
  }
}

h1 {
  font-weight: bold;
}

.dectxt {

  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  opacity: 1;
}

.decaLink {
  display: flex;
  justify-content: space-between;

}

.ClickLink {
  color: #155BD4;
  cursor: pointer;
}